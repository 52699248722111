<script>
// import axios from "axios";
import Layout from "../../layouts/auth";
// import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";
import axios from 'axios';
import { required, email, helpers, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";


import base_domain from '../../myvars'
axios.defaults.baseURL = base_domain
import customTokenCredential from '../../../CustomTokenProvider';
import { BlobServiceClient } from '@azure/storage-blob';
import { PublicClientApplication } from '@azure/msal-browser';
import { mapMutations } from 'vuex';
// import Loader from "../../../components/widgets/loader";
const storageAccountName = 'cmatskasbackup';

/**
 * Forgot Password component
 */
export default {
  setup() {
    return { v$: useVuelidate()  };
  },
  mounted() {
    // var url = new URL(window.location.href)
    // var urlSearchParams = url.searchParams;
    console.log("landed in mount+++++++++++++++++", window.location.href)
    const accounts = this.$msalInstance.getAllAccounts();
    if (window.location.href.includes("logout")){ 
      console.log("inif with logout")
      if (accounts.length != 0) {this.SignOut()}
      else{
        this.account = undefined;
        localStorage.clear();
        sessionStorage.removeItem("msal.interaction.status");
        this.make_it_load=false
      }
    }else{
      console.log("inelse no logout", accounts.length)
      if (accounts.length == 0) {
        this.make_it_load=false
        return;
      }
      this.account = accounts[0];
      this.make_it_load=false
      this.SignIn()
      // this.showForm3 = true;
      console.log('logged in by mounted_________________', this.account)
    }

  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      email: helpers.withMessage("Please enter valid email", email),
    },
    maxlength: {
        required: helpers.withMessage("Maxlength is required", required),
        maxLength: maxLength(4),
    },
  },
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,  
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
    // this.$emitter.on(
    //   'login',
    //   async function (account) {
    //     this.account = account;
    //     console.log(this.account);
    //     console.log('Getting storage data');
    //     await this.getAzureStorageData();
    //     await this.getAccessToken()
    //     this.tryToLogin()
    //   }.bind(this),
    // ),
    this.$emitter.on('logout', async function() {
      console.log('Logging out________');
      // this.account = undefined;
      await this.SignOut()
      console.log('Logging out', this.account);
    }.bind(this),)
  },
  data() {
    return {
      notchoosen: true,
      upass: false,
      email: '',
      showForm1: false, // Initially show the first form
      showForm2: false,
      showForm3: false,
      copiedEmail:'',
      isLoading: false,
      isDisabled:false,
      otp:'',
      toload:true,
      account: undefined,
      containers: [],
      myat: '',
      make_it_load:true,
      showErrorMessage:false,
      myerrors:'',
      signin: 'https://microsoft.com',
    };
  },
  methods: {
    async SignIn() {
      console.log("LOGIN_attempted___________________________")
      if (this.$msalInstance.getAllAccounts()) { // prevents error when already tried msal incomplete
        console.log("Authentication interaction is already in progress, clearing____________");
        sessionStorage.removeItem("msal.interaction.status");
      }
      await this.$msalInstance.initialize();
      console.log("initialized__________")
      await this.$msalInstance
        .loginPopup({})
        .then(() => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          
          // this.$emitter.emit('login', this.account);
          console.log(this.account);
          console.log('Getting storage data');
          this.getAzureStorageData();
          this.getAccessToken()
          this.tryToLogin(true, this.account.username)
          // this.showForm3 = true;

          console.log("this.account________________________", this.account)
        })
        .catch(error => {
          sessionStorage.removeItem("msal.interaction.status");
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      console.log("LOGOUT_attempted___________________________")
      await this.$msalInstance.initialize();
      console.log("initialized__________out")
      await this.$msalInstance
        .logout({})
        .then(() => {
          // this.$emitter.emit('logout', 'logging out');
          // console.log('Logging out________');
          this.account = undefined;
          // this.showForm3 = false;
          localStorage.clear();
          sessionStorage.removeItem("msal.interaction.status");
          // console.log('Logging out', this.account);
        })
        .catch(error => {
          sessionStorage.removeItem("msal.interaction.status");
          console.error(error);
        });
    },
    async tryToReset(overide=false) {
      console.log("this.copiedEmail________________", this.copiedEmail)
      if (!this.copiedEmail) {
        this.myerrors         = "Please enter your email."
        this.showErrorMessage = true
        this.isLoading        = false
        setTimeout(() => {this.showErrorMessage = false}, 1500);
        return
      }
      overide           = (overide == true) ? true : false 
      this.isLoading    = true;
      this.make_it_load = true
      this.isDisabled   = true;
      await axios.post('/accounts/otp_send/', { email: this.copiedEmail, page: 'staff'})
        .then(response => {
          // Handle the response
          console.log('OTP sent successfully:', response.data);
          if (response.data.loginby=="msal" && !overide){
            this.SignIn()
            this.make_it_load     = false
            // this.tryToLogin()
          }else{
            // this.showForm1 = false;//please wait
            // this.showForm3 = false;//sf
            // this.showForm2 = true;//default
            this.myerrors         = "Account not found!"
            this.showErrorMessage = true
            this.isLoading        = false
            this.make_it_load     = false
            setTimeout(() => {this.showErrorMessage = false}, 1500);
          }
        })
        .catch(error => {
          // Handle any errors
          console.error('Error sending email:', error);
        });
    },
    async tryToLogin(remember=false, sr=null) {
      console.log("sr__________________", sr, this.copiedEmail)
      sr = (String(sr).includes('@')) ? sr : this.copiedEmail
      console.log("Login API") 
      //  this.form.personal1.email  = this.copiedEmail 
      // const headers = {'Authorization': `Bearer ${}`, 'Content-Type': 'application/json' // Adjust based on your content type};
      const at = (this.$store.state.accessToken) ? this.$store.state.accessToken : null
      axios.post('/accounts/login/', { email: sr, otp: this.otp, remember: remember, at:at })
      .then(response => {
        // Handle the response
        console.log('response_____________',response.data.status, response.data); 
        if (response.data.status == "SUCCESS"){
            const accessToken = response.data.access;
            const role = response.data.role;
            const appStatus = response.data.app_status;
            const appStatusStage = response.data.app_status_stage;  
            console.log('App status:',appStatus)
            console.log('App status:',appStatusStage)
            
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('userEmail', sr);
            
            if(role === 'staff' || role === 'verification officer'){
              localStorage.setItem('userType', role);
              this.$router.push({ name: 'Dashboard' });
            }
            else{
              localStorage.setItem('userType', 'admin');
              this.$router.push({ name: 'admin dashaboard' });
            }
            // this.$router.push({ name: 'membership_application' });
            
            // this.copiedEmail = this.email;
            // this.showForm1 = false;
            // this.showForm2 = true;
        }else{
          this.myerrors = response.data.error
          this.showErrorMessage = true
          setTimeout(() => {this.showErrorMessage = false}, 1500);
          console.log("this.account__________________", this.account)
          sessionStorage.removeItem("msal.interaction.status");
          localStorage.clear();
          if (this.account) {
            this.account = undefined;
            this.SignOut()
          }
        }
      })
      .catch(error => {console.error('Error sending email:', error);});
    },
    ...mapMutations(['setAccessToken']),
    async getAzureStorageData() {
      if(this.$store.state.accessToken == ''){
        await this.getAccessToken();
      }
      let tokenCredential = new customTokenCredential(this.$store.state.accessToken);
      const blobClient = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net`,
        tokenCredential,
      );

      let i = 1;
      const iter = blobClient.listContainers();
      let containerItem = await iter.next();
      while (!containerItem.done) {
        console.log(`Container ${i++}: ${containerItem.value.name}`);
        this.containers.push({
          id: containerItem.value.properties.etag,
          name: containerItem.value.name,
        });
        containerItem = await iter.next();
      }
    },
    async getAccessToken(){
      let request = {
        scopes: ['https://storage.azure.com/user_impersonation'],
      };
      const msalInstance = new PublicClientApplication(
        this.$store.state.msalConfig,
      );
      try {
        let tokenResponse = await msalInstance.acquireTokenSilent(request);
        console.log("tokenResponse.accessToken______________________", tokenResponse.accessToken)
        this.$store.commit('setAccessToken', tokenResponse.accessToken);
      } catch (error) {
          console.error( 'Silent token acquisition failed. Using interactive mode' );
          let tokenResponse = await msalInstance.acquireTokenPopup(request);
          // console.log(`Access token acquired via interactive auth ${tokenResponse.accessToken}`)
          this.$store.commit('setAccessToken',tokenResponse.accessToken);
      }
    },
    async chasemsal(){
      console.log(this.showForm2, this.upass, this.notchoosen)
      this.showForm2 = true
      this.upass = false
      this.notchoosen = false
      console.log(this.showForm2, this.upass, this.notchoosen)
    },
    async chasemail(){
      console.log(this.showForm2, this.upass, this.notchoosen)
      this.showForm2 = true
      this.upass = true
      this.notchoosen = false
      console.log(this.showForm2, this.upass, this.notchoosen)
    },
  }
};

</script>

<template>
   <div class="loader-main" id="make_it_load" v-if="make_it_load"><div class="loader"></div></div>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-0">
            <div>
              <!-- <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/ECU/ECU-logo.jpg" alt height="34" />
                  </span>
                </div>
              </router-link> -->
            </div>

            <div class="p-2">
                <div class="text-center py-3">
                    <img src="@/assets/images/ECU/ECU-logo.jpg" alt="ECU Logo" height="80" />
                </div>
              <b-alert  v-model="isResetError"  class="mb-4"  variant="danger"  dismissible  >{{ error }}</b-alert>
              <div class="row py-3" v-if="notchoosen">
                <div class="col-12 text-center">
                  <div class="w-md text-dark login_microsoft"  @click="chasemsal()">
                    <!-- @click="tryToReset(false)" -->
                    <div class="innerBtnCnt">
                      <img src="@/assets/images/login_msal.png" alt="ECU Logo" height="25" />
                      <div class="contentBtnMicro ms-3">
                        <h4>Sign in with Microsoft</h4>
                        <p>For Microsoft-365 Account</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="signInOr">
                    <span>OR</span>
                  </div>
                </div>
                <div class="col-12 text-center">
                  <div class="w-md text-dark login_microsoft mailBox mt-2" @click="chasemail()">
                    <div class="innerBtnCnt">
                      <img src="@/assets/images/mail.png" alt="ECU Logo" height="25" />
                      <!-- <i class="bx bx-envelope me-2 fs-5"></i> -->
                      <div class="contentBtnMicro ms-3">
                        <h4 class="m-0">Sign up with email</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              
              <!-- <form class="" @submit.prevent="tryToLogin()" v-if="notchoosen"> -->
              <form class="" @submit.prevent="tryToLogin()" v-if="showForm2">
                <div v-show="showErrorMessage" class="mt-2 text-danger fw-bold"><v-alert color="error">{{myerrors}}</v-alert></div>
                <div class="mb-3">
                  <label for="useremail">Email</label>
                  <input type="email" v-model="copiedEmail" class="form-control" id="useremail" placeholder="Enter email" :class="{ 'is-invalid': submitted && v$.email.$error }" required />
                  <div v-for="(item, index) in v$.email.$errors" :key="index" class="invalid-feedback" >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
                
                <div class="mb-3" v-if="upass">
                  <label for="otpId">Password</label>
                  <input type="password" v-model="otp" class="form-control" id="otpId" placeholder="Enter your password" :class="{ 'is-invalid': submitted && v$.maxlength.$error,}" />
                  <div v-for="(item, index) in v$.maxlength.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                    <div class="mb-3 row mt-3 ">
                  <!-- for mail -->
                    <div class="col-6">
                      <div  @click="notchoosen=!notchoosen;showForm2=!showForm2">
                          <button class="btn btn-theme rounded" type="button">
                            <i class="mdi mdi-arrow-left"></i> Back
                          </button>
                      </div>
                    </div>
                    <div class="col-6 text-end"><button class="btn btn-theme w-md" @click="tryToLogin()">Login</button></div>
                  </div>
                </div>

                <div class="mb-3 row mb-0" v-else>
                  <!-- for msal -->
                  <div class="col-6">
                    <div  @click="notchoosen=!notchoosen;showForm2=!showForm2">
                          <button class="btn btn-theme rounded" type="button">
                            <i class="mdi mdi-arrow-left"></i> Back
                          </button>
                      </div>
                    </div>
                  <div class="col-6 text-end"><button class="btn btn-theme w-md" @click="tryToReset(false)">Login</button></div>
                </div>
                
                <!-- <a class="btn btn-theme w-md mx-2" @click="tryToReset(true)">Password Login</a> -->
                <!-- <a href="#" @click="showForm2=!showForm2;showForm3=!showForm3;isLoading=false"><i class="mdi mdi-arrow-left"></i> Login by Microsoft</a> -->
              </form>
              <form @submit.prevent="tryToReset(true)" v-if="showForm1">
                <div class="mb-3">
                  <label for="useremail">Email</label>
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    id="useremail"
                    placeholder="Enter email"
                    :class="{ 'is-invalid': submitted && v$.email.$error }" :disabled="isDisabled" required 
                  />
                  <div v-if="isLoading">
                    <label for="useremail">Please wait...</label>
                  </div>
                </div>
                
                <div class="mb-3 row mb-0">
                  <div class="col-12 text-end">
                    <button class="btn btn-theme w-md" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
                <!-- <a href="#" @click="showForm1=!showForm1;showForm3=!showForm3">staff login</a> -->
              </form>
              <form @submit.prevent="tryToReset" v-if="showForm3">
                <div class="mb-3">
                  <label for="useremail">Email</label>
                  <input
                    type="email" v-model="email" class="form-control" id="useremail" placeholder="Enter email" :class="{ 'is-invalid': submitted && v$.email.$error }" :disabled="isDisabled" required 
                  />
                  <div v-if="isLoading">
                    <label for="useremail">Please wait...</label>
                  </div>
                </div>
                
                <div class="mb-3 row mb-0">
                  <div class="col-12 text-end">
                    <button class="btn btn-theme w-md mx-2" type="submit">Microsoft Login</button>
                    <a class="btn btn-theme w-md mx-2" @click="tryToReset(true)">Password Login</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            © {{ new Date().getFullYear() }} ECU. Crafted with by <a class="text-primary" target="blank" href="https://midriffinfosolution.org/">MISPL</a>
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>


<style lang="scss" module></style>
